import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useCentroStore } from "../store";
import { useUser, useRole, useAllRoles, useAllFacilityTypes } from "../store/queries/userQueries";
import {Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, TextField, Select, MenuItem, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function RoleListTables() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);
  const { editRole } = useRole();
  const userDataPermissions = userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0];
  const { data: rolesData, refetch, isLoading } = useAllRoles();
  const { data: FacilityTypeData } = useAllFacilityTypes();
  const queryClient = useQueryClient();
  //const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState(null);
    const [reportsToRoles, setReportsToRoles] = React.useState(null);
    const [errorCode, setErrorCode] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState("");

  const columns = [
    { field: "name", headerName: "Role Name", flex: 1 },
    { field: "facilityTypeId", headerName: "Facility Type", flex: 1,
        valueGetter: (params) => {
            return FacilityTypeData?.find(element => element.typeId === params.value).typeName;
        },
    },
    { field: "reportsTo", headerName: "Reports To", flex: 1,
        valueGetter: (params) => {
            if (params.value === null) {
                return "";
            }
            return rolesData.find(element => element.id === params.value).name
        },
    },
    { field: "createdDate", headerName: "Created Date (UTC)", flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
          },
      },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => (
        <EditIcon
          style={{ cursor: "pointer" }}
          onClick={(event) => {
            event.stopPropagation();
            handleOpen(params.row);
          }}
        />
      ),
    },
  ];

  const columnsSpanish = [
    { field: "name", headerName: "Nombre de Rol", flex: 1 },
    { field: "facilityTypeId", headerName: "Tipos de Instalaciones", flex: 1,
        valueGetter: (params) => {
            return FacilityTypeData?.find(element => element.typeId === params.value).typeName;
        },
    },
    { field: "reportsTo", headerName: "Reporta A", flex: 1,
        valueGetter: (params) => {
            if (params.value === null) {
                return "";
            }
            return rolesData.find(element => element.id === params.value).name
        },
    },
    { field: "createdDate", headerName: "FECHA CREADA (UTC)", flex: 1,
        valueGetter: (params) => {
            var temp = params.value.replace('T', ' ');
            return temp;
          },
      },
    {
      field: "edit",
      headerName: "Editar",
      flex: 1,
      renderCell: (params) => (
        <EditIcon
          style={{ cursor: "pointer" }}
          onClick={(event) => {
            event.stopPropagation();
            handleOpen(params.row);
          }}
        />
      ),
    },
  ];

  const handleOpen = (role) => {
      if (userDataPermissions.updateRole === 1) {
          setCurrentRole(role);
          // Creates list of all roles for reports To field, minus the selected role
          var index = rolesData.indexOf(role);
          if (index > -1) { // only splice array when item is found
              var tempRoles = [...rolesData];
              tempRoles.splice(index, 1);
              setReportsToRoles(tempRoles);
          }
          setOpen(true);
      }
      else {
          if (EnglishLanguage) {
              alert("You do not have permission to edit roles.");
          }
          else {
              alert("No tienes permiso para editar funciones.");
          }
      }
  };
  const handleClose = () => {
    setErrorCode(0);
    setCurrentRole(null);
    setOpen(false);
  };

  const handleInputChange = (event, property) => {
    setCurrentRole((prev) => ({ ...prev, [property]: event.target.value }));
  };

  const handleSave = async () => {
    //console.log(currentRole); // to log the updated role data
    let response;
      response = await editRole.mutateAsync(currentRole).catch(function (error) {
          setErrorCode(error.response.status);
          if (error.response.status === 400) {
              if (EnglishLanguage) {
                  setErrorMsg("There was an error saving changes to Role. Required fields are missing.");
              }
              else {
                  setErrorMsg("Se produjo un error al guardar los cambios en el rol. Faltan campos obligatorios.");
              }
          }
          else {
              setErrorMsg(error.message);
          }
      });
      if (response.status === 200) {
          setErrorCode(0);
          refetch(); // Refetch query to update datagrid
          queryClient.refetchQueries({ queryKey: ["user", userId], type: 'active' }); // Refetch user data
          if (EnglishLanguage) {
              alert("Role changes have been successfully saved.");// Successful save message
          }
          else {
              alert("Los cambios de rol se han guardado correctamente.");// Successful save message
          }
      }
      else {
          setErrorCode(response.status);
          setErrorMsg(response.statusText);
          if (EnglishLanguage) {
              alert("There was an error saving changes to Role. Role changes could not be saved.");// Error
          }
          else {
              alert("Se produjo un error al guardar los cambios en el rol. No se pudieron guardar los cambios de rol.");// Error
          }
      }
    handleClose();
  };

  const handleFullAccessRadioChange = (event, property) => {
    setCurrentRole((prev) => ({ ...prev, [property]: event.target.value}));
  };

  const handleRadioChange = (event, property) => {
    var tempPermissions = [...currentRole.permissions]
      tempPermissions[0][property] = event.target.value;
    setCurrentRole((prev) => ({ ...prev, ['permissions']: tempPermissions}));
  };

  const permissionSubSections = {
    //'Facilities:': ['createFac', 'readFac', 'updateFac', 'deleteFac'],
    //'Users:': ['createUser', 'readUser', 'updateUser', 'deleteUser'],
    //'Roles:': ['createRole', 'readRole', 'updateRole', 'deleteRole'],
    //'Reports:': ['createReport', 'readReport', 'updateReport', 'deleteReport', 'approveReport', 'escalateReport', 'approveEscalatedReport']
    'Facilities:': ['createFac', 'readFac', 'updateFac', 'deleteFac'],
    'Users:': ['createUser', 'readUser', 'updateUser', 'deleteUser'],
    'Roles:': ['createRole', 'readRole', 'updateRole'],
    'Reports:': ['createReport', 'approveReport', 'escalateReport', 'approveEscalatedReport'],
    //'Analytics': ['displayAnalytics']
  };

  const permissionSubSectionsSpanish = {
    'Instalaciones:': ['createFac', 'readFac', 'updateFac', 'deleteFac'],
    'Usuarios:': ['createUser', 'readUser', 'updateUser', 'deleteUser'],
    'Roles:': ['createRole', 'readRole', 'updateRole'],
    'Informes:': ['createReport', 'approveReport', 'escalateReport', 'approveEscalatedReport'],
    //'Anal\xEDtica': ['displayAnalytics']
  };

  const permissionDisplayLabels = {
    /*'fullAccess': 'Full Access:',
    'createFac': 'Create Facility:',
    'readFac': 'Read Facility:',
    'updateFac': 'Update Facility:',
    'deleteFac': 'Deactivate Facility:',
    'createUser': 'Create User:',
    'readUser': 'Read User:',
    'updateUser': 'Update User:',
    'deleteUser': 'Deactivate User:',
    'createRole': 'Create Role:',
    'readRole': 'Read Role:',
    'updateRole': 'Update Role:',
    'deleteRole': 'Delete Role:',
    'createReport': 'Create Report:',
    'readReport': 'Read Report:',
    'updateReport': 'Update Report:',
    'deleteReport': 'Delete Report:',
    'approveReport': 'Approve Report:',
    'escalateReport': 'Escalate Report:',
    'approveEscalatedReport': 'Approve Escalated Report:'*/
    'fullAccess': 'Full Access: (Read-Only)',
    'createFac': 'Create Facility:',
    'readFac': 'Read Facility:',
    'updateFac': 'Update Facility:',
    'deleteFac': 'Deactivate Facility:',
    'createUser': 'Create User:',
    'readUser': 'Read User:',
    'updateUser': 'Update User:',
    'deleteUser': 'Deactivate User:',
    'createRole': 'Create Role:',
    'readRole': 'Read Role:',
    'updateRole': 'Update Role:',
    'createReport': 'Create Report:',
    'approveReport': 'Approve Report:',
    'escalateReport': 'Escalate Report:',
    'approveEscalatedReport': 'Approve Escalated Report:',
    //'displayAnalytics' : 'View Analytics:'
  };

  const permissionDisplayLabelsSpanish = {
    'fullAccess': 'Acceso Completo: (Solo-Lectura)',
    'createFac': 'Crear Instalaci\xF3n:',
    'readFac': 'Instalaci\xF3n de Lectura:',
    'updateFac': 'Instalaci\xF3n de Actualizaci\xF3n:',
    'deleteFac': 'Desactivar Instalaci\xF3n:',
    'createUser': 'Crear Usuario:',
    'readUser': 'Leer Usuario:',
    'updateUser': 'Actualizar Usuario:',
    'deleteUser': 'Desactivar Usuario:',
    'createRole': 'Crear Rol:',
    'readRole': 'Leer Rol:',
    'updateRole': 'Actualizar Rol:',
    'createReport': 'Crear Informe:',
    'approveReport': 'Aprobar Informe:',
    'escalateReport': 'Escalar Informe:',
    'approveEscalatedReport': 'Aprobar Informe Escalado:',
    //'displayAnalytics' : 'Puede Ver Datos Anal\xEDticos'
  };


  const modalBody = currentRole ? (
    <Dialog className="role-modal" open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ textTransform: "capitalize" }}>{EnglishLanguage ? "Edit Role Details" : "Editar Detalles del Rol"}</DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
      <FormControl  variant="standard" fullWidth margin="normal">
          <Typography>{EnglishLanguage ? "Name" : "Nombre"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
      <TextField
          label={EnglishLanguage ? "Name" : "Nombre"}
          value={currentRole.name}
          onChange={(event) => handleInputChange(event, 'name')}
          variant="standard"
          fullWidth
          margin="normal"
        />
        </FormControl>
        <FormControl  variant="standard" fullWidth margin="normal">
         <Tooltip title={EnglishLanguage ? "Assign which Facility Type this role belongs to. If there are no Types, they will need to be created in the Facility Types page." 
          : "Asigne a qu\xE9 tipo de instalaci\xF3n pertenece este rol. Si no hay tipos, deber\xE1n crearse en la p\xE1gina Tipos de instalaciones."} arrow>
          <Typography>{EnglishLanguage ? "Facility Type" : "Tipo de Facilidad"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></Typography>
          </Tooltip>
          <Select variant="filled" defaultValue={currentRole.facilityTypeId || ""} onChange={(event) => handleInputChange(event, 'facilityTypeId')} >
            <MenuItem disabled value=""><em>{EnglishLanguage ? "Select Type" : "Seleccione Tipo"}</em></MenuItem>
            {FacilityTypeData.map((type) => (<MenuItem key={type.typeId} value={type.typeId}>{type.typeName}</MenuItem>))}
          </Select>
        </FormControl>
        <FormControl  variant="standard" fullWidth margin="normal">
          <Tooltip title={EnglishLanguage ? "Assign higher role that this role reports to. Leave blank if this role reports to no one."
           : "Asigne un rol superior al que este rol reporta. Deje en blanco si este rol no depende de nadie."} arrow>
          <Typography><br />{EnglishLanguage ? "Reports To: (Optional)" : "Reporta A: (Opcional)"}</Typography>
          </Tooltip>
          <Select variant="filled" defaultValue={currentRole.reportsTo || null} onChange={(event) => handleInputChange(event, 'reportsTo')} >
            <MenuItem value={null}><em>{EnglishLanguage ? "None" : "Ninguno"}</em></MenuItem>
            {reportsToRoles.map((role) => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
          </Select>
        </FormControl>
        <FormLabel component="h3"  sx={{margin: "2rem 0 0rem", padding: "0", textTransform: "uppercase", color: "#3d556c"}}>{EnglishLanguage ? "Permissions" : "Permisos"}:<Tooltip title="Required"><span className="validation"> *</span></Tooltip></FormLabel>
        <FormControl component="fieldset">
          <Tooltip title={EnglishLanguage ? "Full Access grants all permissions to this role. Only assigned for pre-set administrator roles."
            : "El acceso completo otorga todos los permisos para este rol. Solo asignado para roles de administrador preestablecidos."} arrow>
          <Typography><br />{EnglishLanguage ? permissionDisplayLabels['fullAccess'] : permissionDisplayLabelsSpanish['fullAccess']}</Typography>
          </Tooltip>
          <RadioGroup
            row
            value={currentRole.permissions[0].fullAccess === 0 ? 0 : 1}
            //onChange={(event) => handleFullAccessRadioChange(event, 'fullAccess')}
          >
            <FormControlLabel value={1} control={<Radio />} label={EnglishLanguage ? "Allow" : "Conceder"} />
            <FormControlLabel value={0} control={<Radio />} label={EnglishLanguage ? "Deny" : "Negar"} />
          </RadioGroup>
        </FormControl>
        {Object.entries(EnglishLanguage ? permissionSubSections : permissionSubSectionsSpanish).map(([section, permissions]) => (
          <div key={section}>
            <Typography><br />{section}</Typography>
            {permissions.map((permission) => (
              <FormControl className="role-radios"  key={permission} component="fieldset">
                <FormLabel component="h4" sx={{margin: "1rem 0 0rem", padding: "0"}}>{EnglishLanguage? permissionDisplayLabels[permission] : permissionDisplayLabelsSpanish[permission]}</FormLabel>
                <RadioGroup
                  row
                  defaultValue={currentRole.permissions[0][permission] === 0 ? 0 : 1}
                  onChange={(event) => handleRadioChange(event, permission)}
                >
                  <FormControlLabel value={1} control={<Radio />} label={EnglishLanguage ? "Allow" : "Conceder"} />
                  <FormControlLabel value={0} control={<Radio />} label={EnglishLanguage ? "Deny" : "Negar"} />
                </RadioGroup>
              </FormControl>
            ))}
          </div>
        ))}
        <Typography><br />{EnglishLanguage ? "Created Date (UTC, Read-Only)" : "Fecha de creaci\xF3n (UTC, solo-lectura)"}:</Typography>
          <TextField label={EnglishLanguage ? "Created Date" : "Fecha de creaci\xF3n"} defaultValue={currentRole.createdDate} InputProps={{ readOnly: true, }} fullWidth variant="standard" margin="normal" />
        <br />
      </DialogContent>
      <DialogActions>
        {errorCode !== 0 ? <Grid container justifyContent="center" style={{ color: 'red' }}><Grid item pt={0} pb={2} mb={-2}>{errorMsg}</Grid></Grid> : null}
        <Button onClick={handleClose} color="primary">{EnglishLanguage ? "Cancel" : "Cancelar"}</Button>
        <Button onClick={handleSave} color="primary">{EnglishLanguage ? "Save" : "Guardar"}</Button>
      </DialogActions>
    </Dialog>
  ) : null;

  return (
    <div style={{ height: "calc(100vh - 17rem)", width: "100%" }}>
      {modalBody}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          rows={rolesData}
          getRowId={(row) => row.id}
          columns={EnglishLanguage ? columns : columnsSpanish}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ Toolbar: GridToolbar }}
          //onRowClick={RoleDataSetup}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          localeText={{
                    noRowsLabel: EnglishLanguage ? 'No rows' : "Sin filas",
                    noResultsOverlayLabel: EnglishLanguage ? 'No results found.' : "No se han encontrado resultados.",
                    toolbarQuickFilterPlaceholder: EnglishLanguage ? "Search..." : "BUSCAR...",
                    toolbarExport: EnglishLanguage ? "Export" : "EXPORTAR",
                    toolbarExportCSV: EnglishLanguage ? "Export to CSV" : "EXPORTAR A CSV",
                    toolbarExportPrint: EnglishLanguage ? "Print" : "imprimir", 
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            EnglishLanguage ? 
                            `${from}-${to} of ${count}` : 
                            `${from}-${to} de ${count}`, 
                    },
           }}
        />
      )}
    </div>
  );
}
