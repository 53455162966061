import axios from "axios";

//Our centro api client that will use default options based on if we're in dev mode or production for connecting to the c# restful api backend.
const centroClientApi = () => {
    // axios.defaults.baseURL = "https://localhost:7204"; // Local Host URL
    // axios.defaults.baseURL = "https://centro-development-api.infinaweb.com/"; // Development URL
    // axios.defaults.baseURL = "https://centro-test-api.infinaweb.com/"; // Test URL
    axios.defaults.baseURL = "https://centro-api.infinaweb.com/"; // Prod URL
    // axios.defaults.baseURL = "https://centro-demo-api.infinaweb.com/"; // Demo URL
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
    axios.defaults.headers.get['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Response'] = 'response';

    const instance = axios.create();
    //We want to intercept each request being made and attach the JWT authorization token if available
    instance.interceptors.request.use((config) => {
        config.withCredentials = true;
        return config;
    });
    return instance;
};

export default centroClientApi();