import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Home from "@mui/icons-material/Home";
import WarningIcon from "@mui/icons-material/Warning";
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BarChartIcon from "@mui/icons-material/BarChart";
// import MenuIcon from "@mui/icons-material/Menu";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SchoolIcon from "@mui/icons-material/School";
import BuildIcon from "@mui/icons-material/Build";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { useCentroStore } from "../../store";
import { useUser } from "../../store/queries/userQueries";
import TemporaryDrawer from "../TemporaryDrawer";
import CentroLogo from "../../assets/images/centro-logo_whitegold-menu.png";
import MediaQuery from "react-responsive";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Grid from "@mui/material/Unstable_Grid2";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(7)} + 1px)`,
//   },
// });

// White Theme

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(5.5rem)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(5.5rem)`,
  },
});
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function NavBar() {
  const EnglishLanguage = useCentroStore((state) => state.EnglishLanguage);
  const reports = useCentroStore((state) => state.reports);
  const maps = useCentroStore((state) => state.maps);
  const training = useCentroStore((state) => state.training);
  const analytics = useCentroStore((state) => state.analytics);
  const tsap = useCentroStore((state) => state.tsap);
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const userId = useCentroStore((state) => state.userId);
  const { userData } = useUser(userId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <>
      <Drawer variant="permanent" open={open} className={"drawer-wrap " + open}>
        <nav className="navbar">
          <Grid container px={1} py={3}>
            <Grid item display="flex" xs={9} alignItems="center">
              <Link to="/home">
                <img src={CentroLogo} className="logo" alt="logo" />
              </Link>
            </Grid>
            <Grid item display="flex" xs={3}>
              <Tooltip title="Expand/contract side menu.">
                <IconButton className="expand-icons">
                  {open === false ? (
                    <MenuOpenIcon className="handle-chevron-open" onClick={handleDrawerOpen} />
                  ) : (
                    <MenuOpenIcon className="handle-chevron-close" onClick={handleDrawerClose} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).isAdmin === false  ? (
              <ListSubheader component="div" id="nested-list-subheader">
                {EnglishLanguage ? <ListItemText primary="Quick Menu" /> : <ListItemText primary="menú rápido" />}
              </ListSubheader>
            ) : (
              ""
            )}>
            {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).isAdmin === false  ? (
              <Link to="/home">
              <ListItemButton
                sx={{ marginLeft: "0px" }}
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}>
                <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                  <Home />
                </ListItemIcon>
                {EnglishLanguage ? <ListItemText primary="Home" /> : <ListItemText primary="Inicio" />}
              </ListItemButton>
            </Link>
            ) : (
              ""
            )}
            {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).isAdmin === false &&
             reports === true ? (
              <Link to="/incident-reports">
                <ListItemButton
                  sx={{ marginLeft: "0px" }}
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}>
                  <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                    <WarningIcon />
                  </ListItemIcon>
                  {EnglishLanguage ? <ListItemText primary="Reports" /> : <ListItemText primary="informes" />}
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {/* userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].displayAnalytics === 1  ? (
              <Link to="/analytics">
              <ListItemButton
                sx={{ marginLeft: "0px" }}
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}>
                <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                  <AssessmentIcon />
                </ListItemIcon>
                {EnglishLanguage ? <ListItemText primary="Analytics" /> : <ListItemText primary="Analítica" />}
              </ListItemButton>
            </Link>
            ) : (
              ""
            ) */}
            {/* {analytics === true && (
                <Link to="/analytics">
                  <ListItemButton sx={{ marginLeft: "0px" }}
                    selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4)}
                    >
                    <ListItemIcon
                      sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                    >
                      <BarChartIcon />
                    </ListItemIcon>
                    {EnglishLanguage ? (
                      <ListItemText primary="Analytics" />
                    ) : (
                      <ListItemText primary="Analítica" />
                    )}
                  </ListItemButton>
                </Link>
              )} */}
          </List>
             {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readUser === 1 || 
              userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readRole === 1 || 
              userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readFac === 1 ? (
              <List
                sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {EnglishLanguage ? <ListItemText primary="Admin Menu" /> : <ListItemText primary="menú rápido" />}
                  </ListSubheader>
                }>
                        {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].createUser === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].updateUser === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].deleteUser === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readUser === 1 ? (
                      <Link to="/user-list">
                        <ListItemButton
                          sx={{ marginLeft: "0px" }}
                          selected={selectedIndex === 10}
                          onClick={(event) => handleListItemClick(event, 10)}>
                          <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                            <SupervisorAccountIcon />
                          </ListItemIcon>
                          {EnglishLanguage ? <ListItemText primary="Users" /> : <ListItemText primary="usuarios" />}
                        </ListItemButton>
                      </Link>
                    ) : (
                      ""
                    )}
                    {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].createRole === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].updateRole === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].deleteRole === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readRole === 1 ? (
                      <Link to="/role-list">
                        <ListItemButton
                          sx={{ marginLeft: "0px" }}
                          selected={selectedIndex === 11}
                          onClick={(event) => handleListItemClick(event, 11)}>
                          <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                            <ManageAccountsIcon />
                          </ListItemIcon>
                          {EnglishLanguage ? <ListItemText primary="Roles" /> : <ListItemText primary="Roles" />}
                        </ListItemButton>
                      </Link>
                    ) : (
                      ""
                    )}
                    {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].createFac === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].updateFac === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].deleteFac === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readFac === 1 ? (
                      <Link to="/facility-list">
                        <ListItemButton
                          sx={{ marginLeft: "0px" }}
                          selected={selectedIndex === 12}
                          onClick={(event) => handleListItemClick(event, 12)}>
                          <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                            <HomeWorkIcon />
                          </ListItemIcon>
                          {EnglishLanguage ? <ListItemText primary="Facilities" /> : <ListItemText primary="Instalaciones" primaryTypographyProps={{ 
                    style: {
                        whiteSpace: 'wrap',
                        overflow: 'visible',
                    }
                }} />}
                        </ListItemButton>
                      </Link>
                    ) : (
                      ""
                    )}
                    {userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].createFac === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].updateFac === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].deleteFac === 1 ||
                    userData?.data?.data.roles.find(element => element.id === parseInt(userData?.data?.data.roleId)).permissions[0].readFac === 1 ? (
                      <Link to="/facility-type">
                        <ListItemButton
                          sx={{ marginLeft: "0px" }}
                          selected={selectedIndex === 13}
                          onClick={(event) => handleListItemClick(event, 13)}>
                          <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                            <HolidayVillageIcon />
                          </ListItemIcon>
                          {EnglishLanguage ? <ListItemText primary="Facility Type" /> : <ListItemText primary="Tipo de instalación" primaryTypographyProps={{ 
                    style: {
                        whiteSpace: 'wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }} />}
                        </ListItemButton>
                      </Link>
                    ) : (
                      ""
                    )} 
              </List>
            ) : (
              ""
            )}
          {/* <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {EnglishLanguage ? (
                    <ListItemText primary="Settings" />
                  ) : (
                    <ListItemText primary="ajustes" />
                  )}
                </ListSubheader>
              }
            >
              <ListItemButton sx={{ marginLeft: "0px" }}
                    selected={selectedIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6)}
                    >
                <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                  <BuildIcon />
                </ListItemIcon>
                <TemporaryDrawer anchor="right" />
              </ListItemButton>

              <Link to="/menu">
                <ListItemButton sx={{ marginLeft: "0px" }}
                    selected={selectedIndex === 7}
                    onClick={(event) => handleListItemClick(event, 7)}
                    >
                  <ListItemIcon
                    sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                  >
                    <MenuIcon />
                  </ListItemIcon>
                  {EnglishLanguage ? (
                    <ListItemText primary="Menu" />
                  ) : (
                    <ListItemText primary="menú" />
                  )}
                </ListItemButton>
              </Link>
            </List> */}
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {EnglishLanguage ? <ListItemText primary="Resources" /> : <ListItemText primary="Recursos" />}
              </ListSubheader>
            }>
            {training === true && (
              <Link to="/training">
                <ListItemButton
                  sx={{ marginLeft: "0px" }}
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}>
                  <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                    <SchoolIcon />
                  </ListItemIcon>
                  {EnglishLanguage ? <ListItemText primary="Training" /> : <ListItemText primary="capacitación" />}
                </ListItemButton>
              </Link>
            )}
            {tsap === true && (
              <Link to="">
                <ListItemButton
                  sx={{ marginLeft: "0px" }}
                  selected={selectedIndex === 5}
                  onClick={(event) => handleListItemClick(event, 5)}>
                  <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                    <TrackChangesIcon />
                  </ListItemIcon>
                  {EnglishLanguage ? <ListItemText primary="TSAP" /> : <ListItemText primary="TSAP" />}
                </ListItemButton>
              </Link>
            )}
            <Link to="/technical">
              <ListItemButton
                sx={{ marginLeft: "0px" }}
                selected={selectedIndex === 8}
                onClick={(event) => handleListItemClick(event, 8)}>
                <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                  <LibraryBooksIcon />
                </ListItemIcon>
                {EnglishLanguage ? <ListItemText primary="Technical" /> : <ListItemText primary="REFERENCIAS TÉCNICAS" primaryTypographyProps={{ 
            style: {
                whiteSpace: 'wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }} />}
                
              </ListItemButton>
            </Link>
            <Link to="/faq">
              <ListItemButton
                sx={{ marginLeft: "0px" }}
                selected={selectedIndex === 9}
                onClick={(event) => handleListItemClick(event, 9)}>
                <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                  <HelpOutlineIcon />
                </ListItemIcon>
                {EnglishLanguage ? <ListItemText primary="FAQ" /> : <ListItemText primary="PREGUNTAS FRECUENTES" primaryTypographyProps={{ 
            style: {
                whiteSpace: 'wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }} />}
              </ListItemButton>
            </Link>
          </List>
        </nav>
      </Drawer>

      {/* <MediaQuery maxWidth={1367}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="hamburger-menu"
        >
          <MenuIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <nav className="navbar mobile-menu">
            <Link to="/home">
              <Typography variant="h2" mt={3} mb={6}>
                <img src={CentroLogo} className="logo" alt="Centro Logo" />
              </Typography>
            </Link>

            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Quick Menu
                </ListSubheader>
              }
            >
              <Link to="/home">
                <ListItemButton sx={{ marginLeft: "0px" }}>
                  <ListItemIcon
                    sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                  >
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </Link>
              {reports === true && (
                <Link to="/incident-reports">
                  <ListItemButton sx={{ marginLeft: "0px" }}>
                    <ListItemIcon
                      sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                    >
                      <WarningIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                  </ListItemButton>
                </Link>
              )}
              {maps === true && (
                <Link to="/maps">
                  <ListItemButton sx={{ marginLeft: "0px" }}>
                    <ListItemIcon
                      sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                    >
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Maps" />
                  </ListItemButton>
                </Link>
              )}
              {training === true && (
                <Link to="/training">
                  <ListItemButton sx={{ marginLeft: "0px" }}>
                    <ListItemIcon
                      sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                    >
                      <WarningIcon />
                    </ListItemIcon>
                    <ListItemText primary="Training" />
                  </ListItemButton>
                </Link>
              )}
              {analytics === true && (
                <Link to="/analytics">
                  <ListItemButton sx={{ marginLeft: "0px" }}>
                    <ListItemIcon
                      sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                    >
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                  </ListItemButton>
                </Link>
              )}
              {tsap === true && (
                <Link to="">
                  <ListItemButton sx={{ marginLeft: "0px" }}>
                    <ListItemIcon
                      sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                    >
                      <TrackChangesIcon />
                    </ListItemIcon>
                    <ListItemText primary="TSAP" />
                  </ListItemButton>
                </Link>
              )}
            </List>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Settings
                </ListSubheader>
              }
            >
              <ListItemButton sx={{ marginLeft: "0px" }}>
                <ListItemIcon sx={{ minWidth: "33px", marginRight: "1.25rem" }}>
                  <BuildIcon />
                </ListItemIcon>
                <TemporaryDrawer anchor="right" />
              </ListItemButton>

              <Link to="/menu">
                <ListItemButton sx={{ marginLeft: "0px" }}>
                  <ListItemIcon
                    sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                  >
                    <MenuIcon />
                  </ListItemIcon>
                  <ListItemText primary="Menu" />
                </ListItemButton>
              </Link>
            </List>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Resources
                </ListSubheader>
              }
            >
              <Link to="/technical">
                <ListItemButton sx={{ marginLeft: "0px" }}>
                  <ListItemIcon
                    sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                  >
                    <LibraryBooksIcon />
                  </ListItemIcon>
                  <ListItemText primary="Technical" />
                </ListItemButton>
              </Link>
              <Link to="/faq">
                <ListItemButton sx={{ marginLeft: "0px" }}>
                  <ListItemIcon
                    sx={{ minWidth: "33px", marginRight: "1.25rem" }}
                  >
                    <HelpOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="FAQ" />
                </ListItemButton>
              </Link>
            </List>
          </nav>
        </Menu>
      </MediaQuery> */}
    </>
  );
}
